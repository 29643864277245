<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border spinner-external text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpinnerExternal",
};
</script>

<style scoped>
.spinner-external {
  width: 48px;
  height: 48px;
  border-width: 0em;
  /* border: 0.15em; */
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2328AAE1;%7D%0A%3C/style%3E%3Cg id='Artwork_26_1_'%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M12,2.6c5.2,0,9.4,4.2,9.4,9.4c0,5.2-4.2,9.4-9.4,9.4c-5.2,0-9.4-4.2-9.4-9.4C2.6,6.8,6.8,2.6,12,2.6 M12,20.2c4.5,0,8.2-3.7,8.2-8.2c0-4.5-3.7-8.2-8.2-8.2c-4.5,0-8.2,3.7-8.2,8.2C3.8,16.5,7.5,20.2,12,20.2'/%3E%3Cpath class='st0' d='M17.2,12.5c0,2.9-2.3,5.2-5.2,5.2c-2.9,0-5.2-2.3-5.2-5.2H17.2z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
</style>
