import axios from "axios";
import { configValue } from "@/services/config";

export default (config, user) => {
  if (config.url.indexOf("-api}") < 0) return axios(config);

  config.url = config.url.replace(
    "{project-api}",
    configValue("VUE_APP_STATUS_API_EP")
  );
  if (user && user.access_token) {
    config.headers = { Authorization: "Bearer " + user.access_token };
  }

  return axios(config);
};
