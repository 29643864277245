<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h1>Du loggas nu in</h1>
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Laddar</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import oidcmgr from "@/services/oidcmgr";
import store from "@/services/store";

export default {
  async created() {
    try {
      // process callback from auth provider
      var result = await oidcmgr.signinRedirectCallback();
      console.log(JSON.stringify(result));

      // initialize store.userData based on the user
      store.commit("userDataClear");
      await store.dispatch("userInit");

      if (store.state.userData.isAuthenticated) {
        // continue to destination, if the user is now authenticated
        this.$router.push({
          path: result.state !== undefined ? result.state : "/start",
        });
        return;
      }
    } catch {}
    this.$router.push({ name: "Unauthorized" });
  },
};
</script>
<style scoped>
html {
  height: 100%;
}

body {
  background-image: url("../../assets/background-1-sv.jpg");
  color: #fff;
  background-size: cover;
  padding-top: 50px;
  font-size: 1rem;
  height: 100%;
}

h1 {
  font-family: Roboto;
  font-weight: 800;
  font-style: italic;
  font-size: 57px;
  letter-spacing: -1.42px;
  color: #ffffff;
  color: rgb(255, 255, 255);
}
.btn-primary {
  transition: all ease-in-out 0.2s;
  background-color: rgba(99, 164, 187, 0.7);
  font-size: 1rem;
  border-radius: 0;
  border: 0;
  margin-top: 1rem;
}
.btn-primary:hover {
  background-color: #212121;
}
</style>
