import Vue from "vue";
import Router from "vue-router";
import store from "@/services/store";

import Splash from "@/views/Splash.vue";

import Signin from "@/views/auth/Signin.vue";
import Callback from "@/views/auth/Callback.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import Signout from "@/views/auth/Signout.vue";

Vue.use(Router);

let r = new Router({
  mode: "history",
  routes: [
    { path: "/", name: "splash", component: Splash },

    {
      path: "/signin",
      name: "signin",
      meta: { skipUserInit: true },
      component: Signin,
    },
    {
      path: "/callback",
      name: "callback",
      meta: { skipUserInit: true },
      component: Callback,
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      meta: { skipUserInit: true },
      component: Unauthorized,
    },
    {
      path: "/noaccess",
      name: "noaccess",
      meta: {},
      component: () => import("@/views/auth/NoAccess.vue"),
    },
    {
      path: "/signout",
      name: "signout",
      meta: { skipUserInit: true },
      component: Signout,
    },
    {
      path: "/start",
      name: "start",
      meta: { requiresAuth: true },
      component: () => import("@/views/Start.vue"),
    },
    {
      path: "/list",
      name: "list",
      meta: { requiresAuth: true, title: "Ärenden" },
      component: () => import("@/views/list/List.vue"),
    },
    {
      path: "/create",
      name: "create",
      meta: { requiresAuth: true, title: "Skapa AO" },
      component: () => import("@/views/list/Create.vue"),
    },
    {
      path: "/createarea",
      name: "createarea",
      meta: { requiresAuth: true, title: "Skapa Lägenhet" },
      component: () => import("@/views/list/CreateArea.vue"),
    },
    {
      path: "/edit",
      name: "edit",
      meta: { requiresAuth: true, title: "Arbetsorder" },
      component: () => import("@/views/list/Edit.vue"),
    },
    {
      path: "/editarea",
      name: "editarea",
      meta: { requiresAuth: true, title: "Lägenhet" },
      component: () => import("@/views/list/EditArea.vue"),
    },
    {
      path: "/checklist/list",
      name: "checklist-list",
      meta: { requiresAuth: true, title: "Checklistor" },
      component: () => import("@/views/list/ChecklistList.vue"),
    },
    {
      path: "/checklist/edit",
      name: "checklist-edit",
      meta: { requiresAuth: true, title: "Checklista" },
      component: () => import("@/views/list/Checklist.vue"),
    },
    {
      path: "/reportview",
      name: "reportview",
      meta: { requiresAuth: true, title: "Rapport" },
      props: { ep: "report" },
      component: () => import("@/views/list/ReportView.vue"),
    },
    {
      path: "/slides/status",
      name: "statusslide",
      meta: { requiresAuth: true, title: "Slides: Status" },
      component: () => import("@/views/slides/Status.vue"),
    },
  ],
});

r.beforeEach(async (to, from, next) => {
  // ensure that store.state.userData is initialized with any current user and roles
  if (
    to.matched.some((record) => record.meta.requiresAuth) ||
    to.matched.some((record) => !record.meta.skipUserInit)
  ) {
    // we need this if requiresAuth == true but may otherwise skip it if skipUserInit == true
    console.log(
      "router.beforeEach(): start. path is " +
        to.path +
        ", calling store.userInit()"
    );
    await store.dispatch("userInit");
  } else {
    console.log(
      "router.beforeEach(): start. path is " +
        to.path +
        ", skipped store.userInit()"
    );
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.userData.isAuthenticated) {
      console.log(
        "router.beforeEach(): path is " + to.path + ", user is authenticated."
      );
      if (!store.state.userData.roles.includes("se.eaztimate.status")) {
        r.push({ name: "noaccess" });
        next(false);
        return;
      }
      next();
      return;
    } else {
      console.log(
        "router.beforeEach(): path is " +
          to.path +
          ", user is not authenticated. calling store.userLogin()"
      );
      await store.dispatch("userLogin", to.fullPath);

      next(false);
      return;
    }
  } else {
    console.log(
      "router.beforeEach(): path is " +
        to.path +
        ", authentication not required."
    );
    next();
  }
});

export default r;
