import VueAppInsights from "vue-application-insights";
import { configValue } from "@/services/config";

const telemetryInitializer = (store) => (envelope) => {
  const user =
    store.state.userData.user &&
    store.state.userData.user.profile &&
    store.state.userData.user.profile.sub;

  envelope.tags["ai.cloud.role"] = configValue("VUE_APP_AZURE_APP_INSIGHT_APP");

  if (user) {
    envelope.tags["ai.user.authUserId"] = user;
  }
};

export const createApplicationInsight = (Vue, router, store) => {
  Vue.use(VueAppInsights, {
    baseName: configValue("VUE_APP_AZURE_APP_INSIGHT_APP"),
    id: configValue("VUE_APP_AZURE_APP_INSIGHT_ID"),
    router,
    appInsightsConfig: {
      enableResponseHeaderTracking: true,
      enableAjaxErrorStatusText: true,
    },
  });

  Vue.appInsights.addTelemetryInitializer(telemetryInitializer(store));
};
