<template>
  <div>
    <nav
      v-if="userData.isAuthenticated"
      class="navbar navbar-expand-md fixed-top navbar-eaztimate"
    >
      <div class="container">
        <!-- <img src="../assets/ez-logo-new-282828.png" width="24" height="24" class="d-inline-block align-top navbar-logo" alt> -->
        <a class="navbar-brand" href="https://ezadmin.se" target="_blank">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style="enable-background: new 0 0 24 24; width: 24px; height: 24px"
            xml:space="preserve"
          >
            <g id="Ez">
              <path
                class="st0"
                d="M12.24,1.12c6.14,0,11.13,4.99,11.13,11.14c0,6.14-4.99,11.14-11.13,11.14c-6.14,0-11.14-5-11.14-11.14
		C1.11,6.11,6.1,1.12,12.24,1.12 M12.24,21.98c5.36,0,9.72-4.36,9.72-9.72c0-5.36-4.36-9.72-9.72-9.72c-5.36,0-9.72,4.36-9.72,9.72
		C2.52,17.61,6.88,21.98,12.24,21.98"
              />
              <path
                class="st0"
                d="M18.47,12.84c0,3.44-2.79,6.23-6.23,6.23c-3.44,0-6.23-2.79-6.23-6.23H18.47z"
              />
            </g>
          </svg>
        </a>
        <!-- <span v-if="userData.roles.includes('se.eaztimate.status.dev')">[Dev]</span>
                <span v-if="userData.roles.includes('se.eaztimate.status.alfa')">[Alfa]</span>
                <span v-if="userData.roles.includes('se.eaztimate.status.beta')">[Beta]</span>-->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon fas fa-bars"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav align-items-start">
            <router-link
              tag="li"
              to="/start"
              class="nav-item"
              active-class="active"
              v-if="userData.roles.includes('se.eaztimate.status')"
            >
              <a class="nav-link">Start</a>
            </router-link>

            <router-link
              tag="li"
              to="/list"
              class="nav-item mr-auto"
              active-class="active"
              v-if="userData.roles.includes('se.eaztimate.status')"
            >
              <a class="nav-link">Ärenden</a>
            </router-link>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="dropdown01"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >{{ userData.user.profile.name }}</a
              >
              <div class="dropdown-menu" aria-labelledby="dropdown01">
                <a
                  class="dropdown-item"
                  v-if="userData.roles.some((i) => i[0] == 'admin')"
                  @click="alertMessageForRole('admin')"
                  style="display: none"
                  >Endast om 'admin'</a
                >
                <a
                  class="dropdown-item"
                  v-if="userData.roles.some((i) => i[0] == 'user')"
                  @click="alertMessageForRole('user')"
                  style="display: none"
                  >Endast om 'user'</a
                >
                <a
                  class="dropdown-item"
                  v-if="userData.roles.some((i) => i[0] == 'other')"
                  @click="alertMessageForRole('other')"
                  style="display: none"
                  >Endast om 'other'</a
                >
                <router-link to="/signout" class="dropdown-item"
                  >Logga ut</router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav v-if="userData.isAuthenticated" class="fixed-under navbar-eaztimate">
      <ul class="navbar-nav align-items-start">
        <slot name="navbar-frame"></slot>
      </ul>
    </nav>
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<script>
import store from "@/services/store";

export default {
  name: "Layout",
  data() {
    return {
      alertMessage: null,
    };
  },
  methods: {
    alertMessageForRole(v) {
      this.alertMessage = `Det här menyvalet är en TEST och visas endast ifall användaren har rollen &quot;${v}&quot;. Användbart ifall man vill begränsa något i UIt.<br><br>På serversidan, i EzCore så ska användarens inloggning kontrolleras genom att dekorera kontrollern eller metoden med Authorize-attributet. I TestController.TestPrivate2 finns exempel där även rollen kontrolleras.`;
    },
  },
  computed: {
    userData() {
      return store.state.userData;
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Eaztimate - Ska";
    },
  },
};
</script>

<style>
@import "../assets/eaztimate.css";
@import "../assets/dark-layout.css";

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

/* #maincol,
#listcol {
    padding-top: 20px;
} */
.function-bar {
  line-height: 24px;
  padding-left: 5px;
  margin-bottom: 1rem;
}
.function-bar a {
  color: #000;
}
html {
  height: 100%;
}
body {
  padding-top: 50px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

a {
  cursor: pointer;
}
.container {
  padding: 0 !important;
}
.container h1 {
  margin-bottom: 1rem;
}

.container .row {
  text-align: left;
}

.table td,
.table th {
  text-align: left;
}

.navbar-dark .navbar-brand {
  font-size: 16px;
}
.nav-item.active {
  /* text-decoration: underline; */
  font-weight: 800;
}
.nav-item.active .nav-link {
  color: #000;
}
.navbar {
  box-shadow: 1px -19px 44px rgba(0, 0, 0, 0.1);
  padding: 0;
}
.navbar-nav {
  width: 100%;
  padding-left: 10px;
}
.navbar-toggler-icon {
  line-height: 26px;
}
/* .mattias-knapp {
    position: fixed;
    bottom: 40px;
    right: 40px;
    padding: 20px 40px;
    background-color: rgb(230, 63, 179);
    color: #fff;
    font-weight: bold;
    text-decoration: uppercase;
    font-size: 2rem;
    border-radius: 40px;
} */
.fixed-under {
  position: fixed;
  top: 54px;
  right: 0;
  left: 0;
  z-index: 1020;
}

#navbar2 {
  display: flex;
  flex-basis: auto;
  padding: 0.2rem;
  background-color: #e6e6e6;
}
#navbar2 .navbar-nav {
  flex-direction: row;
}
#navbar2 .navbar-nav .nav-link {
  padding: 0.2rem 0.5rem;
}
</style>
