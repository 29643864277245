import Vue from "vue";
import Vuex from "vuex";
import oidcmgr from "@/services/oidcmgr";
import ajax from "@/services/ajax";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userData: {
      isInitialized: false,
      isAuthenticated: false,
      user: null,
      roles: null,
    },
  },
  mutations: {
    userDataInit(state, userData) {
      state.userData = userData;
    },
    userDataClear(state) {
      state.userData = {
        isInitialized: false,
        isAuthenticated: false,
        user: null,
        roles: null,
      };
    },
    messageSet(state, message) {
      state.message = message;
    },
  },
  actions: {
    async userInit({ commit, state }, updatedUser = null) {
      if (updatedUser === null && state.userData.isInitialized) {
        console.log("store.userInit(): already initialized");
        return;
      }

      console.log("store.userInit(): retrieving authenticated user");
      try {
        const u = updatedUser === null ? await oidcmgr.getUser() : updatedUser;
        if (u != null) {
          try {
            console.log(
              "store.userInit(): user retrieved. retrieving roles from api"
            );
            var r = (
              await ajax(
                { method: "get", url: "{project-api}/security/getuserroles" },
                u
              )
            ).data;
            commit("userDataInit", {
              isInitialized: true,
              isAuthenticated: true,
              user: u,
              roles: r,
            });
            console.log(
              "store.userInit(): userData initialized (user and roles)."
            );
          } catch {
            console.log(
              "store.userInit(): failed retrieving roles. logging out."
            );
            //await store.dispatch('userLogout');
          }
        } else {
          commit("userDataInit", {
            isInitialized: true,
            isAuthenticated: false,
            user: null,
            roles: null,
          });
          console.log(
            "store.userInit(): no user retrieved. userData initialized (no user or roles)."
          );
        }
      } catch {
        console.log("store.userInit(): failed retrieving user. logging out.");
        //await store.dispatch('userLogout');
      }
    },
    async userLogin(context, path) {
      console.log(
        "store.userLogin(): userData clear, user removal and redirect to signin started"
      );
      context.commit("userDataClear");
      await oidcmgr.removeUser();
      await oidcmgr.signinRedirect({ state: path });
    },
    async userLogout(context) {
      console.log(
        "store.userLogout(): userData clear, user removal and redirect to signout started"
      );
      context.commit("userDataClear");
      await oidcmgr.signoutRedirect();
    },
  },
});

/*
const initialData = localStorage.getItem('data')
store.replaceState(initialData)
*/

export default store;
