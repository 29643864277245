import Vue from "vue";
import Debounce from "lodash.debounce";
import VueSilentbox from "vue-silentbox";
import Clipboard from "v-clipboard";
import Toasted from "vue-toasted";
import router from "@/router";
import store from "@/services/store";
import App from "@/views/App.vue";
import VueScrollTo from "vue-scrollto";
import { L10n, setCulture, loadCldr } from "@syncfusion/ej2-base";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
// import { loadCldr } from '@syncfusion/ej2-base';
// import { L10n } from '@syncfusion/ej2-base';

import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import gregorian from "cldr-data/main/sv/ca-gregorian.json";
import numbers from "cldr-data/main/sv/numbers.json";
import timeZoneNames from "cldr-data/main/sv/timeZoneNames.json";
import weekData from "cldr-data/supplemental/weekData.json"; // To load the culture based first day of week

import spinnerexternal from "@/components/web/SpinnerExternal.vue";
import vuetify from "./plugins/vuetify";
import { createApplicationInsight } from "@/services/application-insight";

// debugger;
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  sv: {
    daterangepicker: {
      placeholder: "Välj datum",
      startLabel: "Startdatum",
      endLabel: "Slutdatum",
      applyText: "Använd",
      cancelText: "Avbryt",
      selectedDays: "Valda dagar",
      days: "Dagar",
      customRange: "Förvalda tidsrymder",
    },
  },
});
setCulture("sv");
// loadCldr(numberingSystems, gregorian, numbers, weekData);

Vue.use(VueSilentbox);
Vue.use(Clipboard);
Vue.use(Toasted);
Vue.use(Debounce);
Vue.use(DateRangePickerPlugin);
Vue.config.productionTip = false;
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.component("spinnerexternal", spinnerexternal);

createApplicationInsight(Vue, router, store);

new Vue({
  store: store,
  router: router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
